 
import { useContext } from 'react';
import './App.css';
import { Contact } from './Components/Contact/Contact';
import { Experience } from './Components/Experience/Experience';
import { Footer } from './Components/Footer/Footer';
import Intro from './Components/Navbar/intro/Intro';
import { Navbar } from './Components/Navbar/Navbar';
import { Portfolio } from './Components/Portfolio/Portfolio';
import { Services } from './Components/Services/Services';
import { Works } from './Components/Works/Works';
import {themeContext} from './context'


function App() {
  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode;
  return (

    <div className='App'
    
    style={{
      background : darkMode? 'black' : '',
      color :  darkMode? 'white':'',



    }}
    
    
    
    >
     
     <Navbar/>
     <Intro/>
     <br/>
     <br/>
     <Services/>
     <br/>
     <Experience/>
     <br/>
     <Works/>
     <br/>
      
     <Portfolio/>
      
     <br/>
     <br/>
     <br/><br/>
      
     <Contact/>
     <br/>
     <br/>
     <br/>
     <br/>
     <br/>
     <br/>
     <Footer/>
    </div>

  );
     
     


}

export default App;
