import React from 'react';
import './Floating.css';
import Crown from '../../../img/crown.png';
import { themeContext } from '../../../context.js'
import { useContext } from 'react';


export const FloatingDiv = ({image, txt1,txt2}) => {

  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode;

  return (
    <div className="floatingdiv"   style={{
      background : darkMode? 'white' : '',
      color :  darkMode? 'black':'',



    }}>
      <img src={image} alt="" />
      <span >
         {txt1}
         <br/>
         {txt2}
         
      </span>


    </div>
  )
}
