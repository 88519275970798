import React from 'react'
import './Works.css'
import Upwork from '../../img/Upwork.png';
import Fiverr from '../../img/fiverr.png';
import Amazon from '../../img/amazon.png';
import Shopify from '../../img/Shopify.png';
import Facebook from '../../img/Facebook.png';
import { themeContext } from '../../context'
import { useContext } from 'react';
import { motion } from 'framer-motion';
import { Link as LinkScroll } from 'react-scroll/modules'
import {link as LinkRouter} from 'react-dom'

export const Works = () => {

  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode;

  return (
    <div className='works'>

{/* left side */}
   
<div className="awesome">
   <span style={{
      
      color :  darkMode? 'white':'',



    }}>Works for All these</span>
   <span>Brands & Clients</span>
   <span>

   I have worked with some of the most well-known companies in the world 
<br />
as a freelancer
 
</span>
  
  < LinkScroll to="Contact" spy={true} smooth={true}><button className='button s-button'>Contact me</button></LinkScroll>
  
  <div className='blur s-blur' style={{background:"ABF1FF94"}}></div>

 </div>

 {/* right side */}
     <div className='w-right'>
     <motion.div 
     initial={{rotate: 45}}
     whileInView={{rotate:0}}
     viewport={{margin: '40px'}}
     transition={{duration:3.5, type: 'spring'}}
     
     
     
     
     
     
     className='w-mainCircle'>
     <div className='w-seeCircle'>

     <img src={Upwork} alt='' />

     </div>

     <div className='w-seeCircle'>
     <img src={Fiverr} alt='' />
         </div>
        
         <div className='w-seeCircle'>
         <img src={Amazon} alt='' />
     </div>

     <div className='w-seeCircle'>
     <img src={Shopify} alt='' />
         </div>

         <div className='w-seeCircle'>
         <img src={Facebook} alt='' />
         </div>
     </motion.div>


     {/* background Circle */}

     <div className='w-backCircle blueCircle'></div>
     <div className='w-backCircle yellowCircle'></div>





     </div>
    </div>
  )
}
