import "./Intro.css"
import React from 'react';
import Github from '../../../img/github.png';
import LinkedIn from '../../../img/linkedin.png';
import Instagram from '../../../img/instagram.png';
import Vector1 from '../../../img/Vector1.png';
import Vector2 from '../../../img/Vector2.png';
import boy from '../../../img/boy.png';
import thumbup from '../../../img/thumbup.png';
import Crown from '../../../img/crown.png';
import glassesimoji from '../../../img/glassesimoji.png';
import { FloatingDiv } from "../FloatingDiv/FloatingDiv";
import { themeContext } from '../../../context'
import { useContext } from 'react';
import { motion } from "framer-motion";
import { Link as LinkScroll } from 'react-scroll/modules'
import {link as LinkRouter} from 'react-dom'




 const Intro = () => {

  const transition = {duration : 2, type: 'spring'}

  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode;


  return (
    
    <div className="intro">

       <div className="i-left">

          
      <div className="i-name"></div>
      <span className="i-name1"  style={{
      background : darkMode? 'black' : '',
      color :  darkMode? 'white':'',



    }}>Hy! Im </span>
      <span className="i-name2">Oussama Mabrouk </span>
      <span className="i-name3">Fullstack Developer with high level of experience in web
      designing and development   producting the Quality work</span>
    

      <LinkScroll to="Contact" spy={true} smooth={true}><button className="button i-button">Contact me</button></LinkScroll>

     <div className="i-icons">

        <a href="https://github.com/oussamaDA12"><img src={Github } alt="" /></a>
        <a href="https://www.linkedin.com/in/oussama-mabrouk-7822b81b1/"><img src = {LinkedIn} alt= "" /></a>
        <a href="https://www.instagram.com/oussama.mab8/"> <img src={Instagram } alt="" /></a>


     </div>

       </div>
       <div className="i-right">

          
       <img className="i-right1" src={Vector1} alt="" />
       <img className="i-right2" src={Vector2} alt="" />
       <img  className="i-right3" src={boy} alt="" />
       <motion.img  
       initial={{left: '-36%'}}
       whileInView={{left: '-24%'}}
       transition={{transition}}
       
       className="i-right4" src={glassesimoji} alt="" />
        
       <motion.div 
       
       initial={{top:'-4%' , left:'74%'}}
       whileInView={{left:'68%'}}
       transition={transition}
       
       
       
       
       
       style={{top:'-4%' , left:'68%'}}
       className="floating-div"
       
       
       >

       <FloatingDiv image={Crown} txt1='web' txt2='Developer'/ >


      </motion.div>

      <motion.div 
      
      initial={{left: '9rem' , top:'18rem'}}
      whileInView={{left:'0rem'}}
      transition={transition}
      
      
      
      style={{top :'18rem' , left:'0rem'}}>
      <FloatingDiv image={thumbup} txt1='Best Disgn' txt2='Award' />

      </motion.div>

      {/* blur divs */}

      <div className="blur" style={{background:"rgb(238 210 255)"}}></div>
       

       </div>
     </div>
  )
}

export default Intro
