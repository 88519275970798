import React from 'react'
import "./Card.css"


export const Card = ({emoji,heading,detail}) => {

  


  return (

    <div className="card">
    <img src= {emoji} alt=""/>
    <span >{heading}</span>
    <span className='n-card2'>{detail}</span>
   </div>
  )
}
