import React from 'react'
import './Footer.css'
import wave from '../../img/wave.png'
import Insta from '@iconscout/react-unicons/icons/uil-instagram'
import Facebook from '@iconscout/react-unicons/icons/uil-facebook'
import Github from '@iconscout/react-unicons/icons/uil-github'

export const Footer = () => {
  return (
    <div className ='footer'>
    <img src={wave} alt=""  style={{width:'100%'}}/>
    <div className ='f-content'>
   
    <span className='span1'>Oussamaup301@gmail.com</span>
     
        <div className='f-icons'>

         <a  href='https://www.instagram.com/oussama.mab8/'><Insta  color='white' size='2rem' /></a>
         <a href='https://www.facebook.com/' ><Facebook    color='white' size='2rem' /></a>
         <a  href='https://github.com/oussamaDA12'><Github    color='white' size='2rem' /></a>



        </div>

        

    </div>
</div>
  )
}
