import React, { useState } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { themeContext } from '../../context'
import { useContext } from 'react';


export const Contact = () => {

  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode;

    const form = useRef();
   const [done, setDone] = useState(false)
 
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_5ecz695', 'template_53ln0tq', form.current, {
            publicKey: 'O7thGkKoBJ1K5ck4h',
          })
          .then(
            () => {
              console.log('SUCCESS!');
              setDone(true);
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };













  return (

    

    <div className='contact-form' id='Contact'>
     
       <div className='w-left'>
         <div className='awesome'>


         <span className='sp1'    style={{
      
      color :  darkMode? 'white':'',



    }}> Get in touch</span>
         <span className=''> Contact me</span>
         <div className='blur s-blur1' style={{background:"#ABF1FF94"}}></div>

         </div>
       </div>

       <div className='c-right'>
         <form ref={form} onSubmit={sendEmail}>

        <input type="text" name="user_name" className="user" placeholder="Name" />
        <input type="email" name="user_email" className="user" placeholder="Email" />
        <textarea  name="message" className="user" placeholder="Message" />
        <input type="submit" value="Send" className='button'/>
        <span>{done && "Thanks for contacting me !"} </span>
        <div className='blur c-blur1' style={{background:"var(--purple)"}}>
         
            
        </div>





         </form>






       </div>



    </div>
  )
}