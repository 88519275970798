import React from 'react'
import Toggle from '../Toggle/Toggle'
import './Navbar.css'
import { Link as LinkScroll } from 'react-scroll/modules'
import {link as LinkRouter} from 'react-dom'




export const Navbar = () => {
  return (
    <div className="n-wrappe">

    <div className="n-left">
        <div className='n-name'>Oussama</div>
         <Toggle/>

    </div>
    <div className="n-right">

        <div className="n-list">

           <ul style={{listStyleType:'none'}}>

           <LinkScroll activeClass='active' to='Intro' spy={true} smooth={true} offset={0} duration={500}><li className='li1'>Home</li> </LinkScroll>
    
     
       
           <LinkScroll activeClass='active' to='Services' spy={true} smooth={true} offset={0} duration={500}> <li className='li1'>Services</li> </LinkScroll>

           <LinkScroll activeClass='active' to='Experiences' spy={true} smooth={true} offset={0} duration={500}> <li className='li1'>Experiences</li></LinkScroll>

           <LinkScroll activeClass='active' to='Portfolio' spy={true} smooth={true} offset={0} duration={500}> <li className='li1'>Portfolio</li> </LinkScroll>


         
         

           </ul>
           </div>


 
           <LinkScroll to="Contact" spy={true} smooth={true}>
        <button className="button n-button">Contact</button>
        </LinkScroll>



    </div>


    </div>
  )
}
