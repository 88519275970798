import React from 'react';
import './Services.css';
import HeartEmoji from '../../img/heartemoji.png';
import Glasses from '../../img/glasses.png';
import humble from '../../img/humble.png';
import { Card } from '../Card/Card';
import Resume from '../Services/OUSSAMA.pdf'
import { themeContext } from '../../context'
import { useContext } from 'react';
import { motion } from 'framer-motion';



export const Services = () => {


  const transition = {duration : 1, type: 'spring'}

  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode;
  return (
    
   <div className='services' id="Services" >

   {/* left side*/}

   <div className="awesome">
   <span  style={{
      
      color :  darkMode? 'white':'',



    }}>My Awesome</span>
   <span>services</span>
   <span>
   I provide custom web development solutions for both individuals and
   <br />
     businesses, leveraging the  latest innovations  in technology to ensure
   <br />
    efficiency, scalability and top-notch
     
    <br/>
 user experience.
</span>
  <a href={Resume} download>
  <button className='button s-button'>Download CV</button>
  </a>
  <div className='blur s-blur' style={{background:"ABF1FF94"}}></div>




   </div>

     {/* right side*/}

     <div className='cards'>

     <motion.div 
      whileInView={{left:'14rem'}}
      initial={{left:'25rem'}}
      transition={transition}
     
     
     
     
     
     
     
     style={{left:'14rem'}}>
  

     <Card 

emoji = {Glasses}
heading = {'Developer'}
detail = {"Html, css , React , Laravel , Php , Js"}


/>
</motion.div>

<motion.div

initial={{ left: "-11rem", top: "12rem" }}
          whileInView={{ left: "-4rem" }}
          transition={transition}




 style={{top:'12rem', left:'-4rem'}}>
  

  <Card 

emoji = {HeartEmoji}
heading = {'Marketing'}
detail = {"Stratégie marketing , Email marketing , Montage vidéo , Design publicitaire "}

/>



</motion.div>

<motion.div  


initial={{ top: "19rem", left: "25rem" }}
          whileInView={{ left: "12rem" }}
          transition={transition}





style={{top:'19rem', left:'12rem'}}>

<Card 

emoji = {humble }
heading = {'UI/UX'}
detail = {"Figma , Sketch , Adobe XD , InVision"}

/>
   

</motion.div>

     </div>

     </div>

  

  )
}
