import React from 'react'
import './Experience.css'
import { themeContext } from '../../context'
import { useContext } from 'react';

export const Experience = () => {

  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode;



  return (
    <div className='experience' id='Experiences'>

   <div className='achievement' >
       <div className='circle' style={{
      
      color :  darkMode? 'black':'',



    }}>3+</div>
       <span>years</span>
       <span>Experience</span>
   </div>
   <div className='achievement'>
       <div className='circle' style={{
      
      color :  darkMode? 'black':'',



    }}>6+</div>
       <span>completed</span>
       <span>Projects</span>
   </div>

   <div className='achievement'>
       <div className='circle' style={{
      
      color :  darkMode? 'black':'',



    }}>5+</div>
       <span>companies</span>
       <span>Work</span>
       
   </div>




    </div>
  )
}
